import type { MqttContainer, MqttValue } from './types'

export const instantaneousCadence: MqttValue = {
  title: 'cadence',
  unit: 'RPM',
  topic: 'bikefitting/resistance/indoorBikeData/instantaneousCadence',
}

export const accumulatedPower: MqttValue = {
  title: 'accumulated power',
  unit: 'W',
  topic: 'bikefitting/resistance/accumulated_power',
}

export const instantaneousPower: MqttValue = {
  title: 'power',
  unit: 'W',
  topic: 'bikefitting/resistance/instantaneous_power',
}

export const distance: MqttValue = {
  title: 'distance',
  unit: 'm',
  topic: 'bikefitting/resistance/distance',
}

export const speed: MqttValue = {
  title: 'speed',
  unit: 'km/h',
  topic: 'bikefitting/resistance/speed',
  decimalPlaces: 1,
}

export const wheelCircumfrence: MqttValue = {
  title: 'wheel circumference',
  unit: 'm/s',
  topic: 'bikefitting/resistance/wheel_circumfrence',
}

export const resistanceLevel: MqttValue = {
  title: 'resistance level',
  unit: '%',
  topic: 'bikefitting/resistance/resistance_level',
  editable: true,
  decimalPlaces: 1,
}

export const powerTarget: MqttValue = {
  title: 'power target',
  unit: 'W',
  topic: 'bikefitting/resistance/power_target',
  editable: true,
}

export const resistanceDefinitions: MqttContainer = {
  title: 'Resistance',
  prefix: 'resistance/',
  values: [
    instantaneousCadence,
    accumulatedPower,
    instantaneousPower,
    distance,
    speed,
    wheelCircumfrence,
    resistanceLevel,
    powerTarget,
  ],
}

export default resistanceDefinitions
